
import { defineComponent, onMounted, watch, ref } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { ApiAuth } from "@/core/api";
import { useI18n } from "vue-i18n";

interface User {
  id: number;
  username: string;
  email: string;
  firstname: string;
  lastname: string;
  language: string;
  advanced_manage: number;
}

export default defineComponent({
  name: "user-overview",
  components: {},
  setup() {
    const loading = ref<boolean>(true);
    const route = useRoute();
    const store = useStore();
    const { t } = useI18n();

    const userInfo = ref<User>();
    userInfo.value = store.getters.currentUser;

    const formData = ref({
      status: 0,
      username: "",
      email: "",
      password: "",
      firstname: "",
      lastname: "",
      mobile: "",
      telephone: "",
      address: "",
      postcode: "",
      city: "",
      country_iso_2: "",
      language: "en_US",
    });

    watch(
      () => store.getters.currentUser,
      (newVal) => {
        userInfo.value = newVal;
      }
    );

    const getUserData = () => {
      loading.value = true;
      ApiAuth.getUserInfo({ id: userInfo.value?.id })
        .then(({ data }) => {
          loading.value = false;
          if (data.code == 0) {
            formData.value = data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs(t("user.overview"), [
        { name: t("user.profile"), path: "" },
      ]);
      getUserData();
    });

    return {
      t,
      loading,
      formData,
      userInfo,
    };
  },
});
