import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  class: "card mb-5 mb-xl-10",
  id: "kt_profile_details_view"
}
const _hoisted_2 = { class: "card-header" }
const _hoisted_3 = { class: "card-title m-0" }
const _hoisted_4 = { class: "fw-bolder m-0" }
const _hoisted_5 = { class: "card-body p-9" }
const _hoisted_6 = { class: "row mb-7" }
const _hoisted_7 = { class: "col-lg-4 fw-bold text-muted" }
const _hoisted_8 = { class: "col-lg-8" }
const _hoisted_9 = { class: "fw-bolder fs-6 text-dark" }
const _hoisted_10 = { class: "row mb-7" }
const _hoisted_11 = { class: "col-lg-4 fw-bold text-muted" }
const _hoisted_12 = { class: "col-lg-8" }
const _hoisted_13 = {
  key: 0,
  class: "badge badge-light-success"
}
const _hoisted_14 = {
  key: 1,
  class: "badge badge-light-danger"
}
const _hoisted_15 = { class: "row mb-7" }
const _hoisted_16 = { class: "col-lg-4 fw-bold text-muted" }
const _hoisted_17 = { class: "col-lg-8" }
const _hoisted_18 = { class: "fw-bolder fs-6 text-dark" }
const _hoisted_19 = { class: "row mb-7" }
const _hoisted_20 = { class: "col-lg-4 fw-bold text-muted" }
const _hoisted_21 = { class: "col-lg-8" }
const _hoisted_22 = { class: "fw-bolder fs-6 text-dark" }
const _hoisted_23 = { class: "row mb-7" }
const _hoisted_24 = { class: "col-lg-4 fw-bold text-muted" }
const _hoisted_25 = { class: "col-lg-8" }
const _hoisted_26 = { class: "fw-bolder fs-6 text-dark" }
const _hoisted_27 = { class: "row mb-7" }
const _hoisted_28 = { class: "col-lg-4 fw-bold text-muted" }
const _hoisted_29 = { class: "col-lg-8" }
const _hoisted_30 = { class: "fw-bolder fs-6 text-dark" }
const _hoisted_31 = { class: "row mb-7" }
const _hoisted_32 = { class: "col-lg-4 fw-bold text-muted" }
const _hoisted_33 = { class: "col-lg-8" }
const _hoisted_34 = { class: "fw-bolder fs-6 text-dark" }
const _hoisted_35 = { class: "row mb-7" }
const _hoisted_36 = { class: "col-lg-4 fw-bold text-muted" }
const _hoisted_37 = { class: "col-lg-8" }
const _hoisted_38 = { class: "fw-bolder fs-6 text-dark" }
const _hoisted_39 = { class: "row mb-7" }
const _hoisted_40 = { class: "col-lg-4 fw-bold text-muted" }
const _hoisted_41 = { class: "col-lg-8" }
const _hoisted_42 = { class: "fw-bolder fs-6 text-dark" }
const _hoisted_43 = { class: "row mb-7" }
const _hoisted_44 = { class: "col-lg-4 fw-bold text-muted" }
const _hoisted_45 = { class: "col-lg-8" }
const _hoisted_46 = { class: "fw-bolder fs-6 text-dark" }
const _hoisted_47 = { class: "row mb-7" }
const _hoisted_48 = { class: "col-lg-4 fw-bold text-muted" }
const _hoisted_49 = { class: "col-lg-8" }
const _hoisted_50 = { class: "fw-bolder fs-6 text-dark" }
const _hoisted_51 = { class: "row mb-7" }
const _hoisted_52 = { class: "col-lg-4 fw-bold text-muted" }
const _hoisted_53 = { class: "col-lg-8" }
const _hoisted_54 = { class: "fw-bolder fs-6 text-dark" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.t("user.profileDetails")), 1)
      ]),
      _createVNode(_component_router_link, {
        to: '/profile/settings',
        class: "btn btn-primary align-self-center"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t("user.editProfile")), 1)
        ]),
        _: 1
      })
    ]),
    _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.t("user.userName")), 1),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.formData.username), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("label", _hoisted_11, _toDisplayString(_ctx.t("common.status")), 1),
        _createElementVNode("div", _hoisted_12, [
          (_ctx.formData.status == 10)
            ? (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString(_ctx.t("user.activated")), 1))
            : (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(_ctx.t("user.deactivated")), 1))
        ])
      ]),
      _createElementVNode("div", _hoisted_15, [
        _createElementVNode("label", _hoisted_16, _toDisplayString(_ctx.t("user.email")), 1),
        _createElementVNode("div", _hoisted_17, [
          _createElementVNode("span", _hoisted_18, _toDisplayString(_ctx.formData.email), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_19, [
        _createElementVNode("label", _hoisted_20, _toDisplayString(_ctx.t("user.firstName")), 1),
        _createElementVNode("div", _hoisted_21, [
          _createElementVNode("span", _hoisted_22, _toDisplayString(_ctx.formData.firstname), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_23, [
        _createElementVNode("label", _hoisted_24, _toDisplayString(_ctx.t("user.lastName")), 1),
        _createElementVNode("div", _hoisted_25, [
          _createElementVNode("span", _hoisted_26, _toDisplayString(_ctx.formData.lastname), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_27, [
        _createElementVNode("label", _hoisted_28, _toDisplayString(_ctx.t("user.mobile")), 1),
        _createElementVNode("div", _hoisted_29, [
          _createElementVNode("span", _hoisted_30, _toDisplayString(_ctx.formData.mobile), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_31, [
        _createElementVNode("label", _hoisted_32, _toDisplayString(_ctx.t("user.telephone")), 1),
        _createElementVNode("div", _hoisted_33, [
          _createElementVNode("span", _hoisted_34, _toDisplayString(_ctx.formData.telephone), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_35, [
        _createElementVNode("label", _hoisted_36, _toDisplayString(_ctx.t("user.address")), 1),
        _createElementVNode("div", _hoisted_37, [
          _createElementVNode("span", _hoisted_38, _toDisplayString(_ctx.formData.address), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_39, [
        _createElementVNode("label", _hoisted_40, _toDisplayString(_ctx.t("user.zipCode")), 1),
        _createElementVNode("div", _hoisted_41, [
          _createElementVNode("span", _hoisted_42, _toDisplayString(_ctx.formData.postcode), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_43, [
        _createElementVNode("label", _hoisted_44, _toDisplayString(_ctx.t("user.city")), 1),
        _createElementVNode("div", _hoisted_45, [
          _createElementVNode("span", _hoisted_46, _toDisplayString(_ctx.formData.city), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_47, [
        _createElementVNode("label", _hoisted_48, _toDisplayString(_ctx.t("user.country")), 1),
        _createElementVNode("div", _hoisted_49, [
          _createElementVNode("span", _hoisted_50, _toDisplayString(_ctx.formData.country_iso_2), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_51, [
        _createElementVNode("label", _hoisted_52, _toDisplayString(_ctx.t("user.language")), 1),
        _createElementVNode("div", _hoisted_53, [
          _createElementVNode("span", _hoisted_54, _toDisplayString(_ctx.formData.language), 1)
        ])
      ])
    ])), [
      [_directive_loading, _ctx.loading]
    ])
  ]))
}